import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
        loadPath: "/assets/i18n/{{ns}}/{{lng}}.json"
    },
    lng: localStorage.getItem("lng") ?? "uk",
    ns: [
      "common", 
      "menu", 
      "login", 
      "chats", 
      "matchdays", 
      "stats", 
      "matchday-details",
      "priority",
      "landing"
    ],
    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ","
    },
  });

  export default i18n;