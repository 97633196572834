export const anonymousPaths = {
    landing: '/',
    login: '/login',
    regularLogin: '/regular-login',
}

export const playerPaths = {
    home: '/home',
    chatList: '/chat',
    chatDetails: '/chat/:chatId',
    userSettings: '/settings',
    matchdayList: '/chat/:chatId/matchday',
    manigaList: '/chat/:chatId/maniga',
    chatUsers: '/chat/:chatId/users',
    chatSettings: '/chat/:chatId/settings',
    priorityPlayers: '/chat/:chatId/priority-players',
    chatStats: '/chat/:chatId/stats',
    matchdayDetails: '/chat/:chatId/matchday/:date/*'
}

