import { lazy } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store';
const AnonymRoutes = lazy(() => import('./routes/anonym-routes'));
const PlayerRoutes = lazy(() => import('./routes/player-routes'));
import { Actions as AuthActions, Selectors as AuthSelectors } from 'components/login/auth.controller';
import './custom.css'
import { useEffect } from 'react';
import { Loading } from 'components/shared/loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/uk'
import 'moment/locale/ru'

interface StateProps {
    isLoggedIn: boolean
    isParseTokenProcessing: boolean
    isLoginProcessing: boolean
}

interface DispatchProps {
    parseToken: () => void
}

function App(props: StateProps & DispatchProps){
    useEffect(() => {
        props.parseToken();
    }, [])

    const renderRoutes = () => {
        const { isLoggedIn } = props;
        if(isLoggedIn){
            return <PlayerRoutes />;
        }
        return <AnonymRoutes />;
    }

    const { 
        isParseTokenProcessing, 
        isLoginProcessing 
    } = props;

    if(isParseTokenProcessing || isLoginProcessing){
        return <Loading />
    }

    return <>
        {renderRoutes()}
        <ToastContainer />
    </>
}

const mapStateToProps = (state: AppState) => {
    const { 
        token, 
        isParseTokenProcessing, 
        isLoginProcessing 
    } = AuthSelectors.getAuth(state);
    return {
        isLoggedIn: !!token,
        isParseTokenProcessing: isParseTokenProcessing,
        isLoginProcessing: isLoginProcessing,
    }
}

const mapDispatchToProps = {
    parseToken: AuthActions.parseToken
}

export default connect(mapStateToProps, mapDispatchToProps)(App)