import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth.slice'
import matchDayRootReducer from './matchday'
import chatRootReducer from './chat'
import matchRootReducer from './match'
import manigaUserListReducer from './maniga-user-list.slice'
import botConfigurationReducer from './bot-configuration.slice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        chat: chatRootReducer,
        matchDay: matchDayRootReducer,
        match: matchRootReducer,
        manigaUserList: manigaUserListReducer,
        configuration: botConfigurationReducer
    }
})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch