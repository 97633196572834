import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Chat } from 'api/chat/models/chat.model'
import { MatchDayList } from 'api/matchday/models/matchDayList.model';
import { MatchDay } from 'api/matchday/models/matchDay.model';
import { MatchList } from 'api/match/models/matchList.model';
import { Match } from 'api/match/models/match.model';
import { TeamResult } from 'api/match/models/teamResult.model';

export interface MatchListState {
    isLoading: boolean,
    teamResults: TeamResult[];
    matches: Match[];
}

const initialState: MatchListState = {
    isLoading: false,
    teamResults: null,
    matches: [],
}

export const matchListSlice = createSlice({
    name: 'match-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setMatchList: (state, action: PayloadAction<MatchList>) => {
            const { payload } = action;
            state.teamResults = payload.teamResults;
            state.matches = payload.matches;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setLoading, 
    setMatchList,
    dispose
} = matchListSlice.actions

export default matchListSlice.reducer