import { combineReducers } from 'redux';
import matchDayListReducer from './match-day-list.slice'
import recentMatchDaysReducer from './recent-matchdays.slice'
import matchDayDetailsReducer from './match-day-details.slice'
import customTeamModalReducer from './custom-team-modal.slice'

const matchDayRootReducer = combineReducers({
    list: matchDayListReducer,
    details: matchDayDetailsReducer,
    customTeamModal: customTeamModalReducer,
    recentList: recentMatchDaysReducer,
});

export default matchDayRootReducer;