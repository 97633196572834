import { AppState } from "store";
import { clearAuthData, setAuthData, setLoading, setParseTokenProcessing, setToken, setLoginProcessing } from "store/auth.slice";
import AuthService from "api/auth/auth.service";
import { LoginResponseModel } from "api/auth/models/login-response.model";
import { LoginArgs } from "api/auth/models/login.model";
import { anonymousPaths, playerPaths } from "routes/paths";

export class Actions {
    public static loginWithTelegram(args: any, from: Location) {
        return async (dispatch: any, getState: () => AppState) => {
            dispatch(setLoginProcessing(true))
            try {
                const data = await AuthService.loginByTelegram(args);
                dispatch(Actions.handleLoginResponse(data, from));
            } catch(ex){
                console.error(ex);
            } finally{
                dispatch(setLoginProcessing(false))
            }
        }
    }

    public static login(args: LoginArgs, from: Location){
        return async (dispatch: any, getState: () => AppState) => {
            dispatch(setLoginProcessing(true))
            try {
                const data = await AuthService.login(args);
                dispatch(Actions.handleLoginResponse(data, from));
            } catch(ex){
                console.error(ex);
            } finally{
                dispatch(setLoginProcessing(false))
            }
        }
    }

    public static logout(){
        return async (dispatch: any, getState: () => AppState) => {
            await dispatch(clearAuthData());

            localStorage.removeItem('jwt');

            window.open(anonymousPaths.landing, '_self')
        }
    }

    public static refreshAuth(){
        return async (dispatch: any, getState: () => AppState) => {
            try {
                await dispatch(setLoading(true));
                const data = await AuthService.refreshAuth();
                await dispatch(setAuthData(data));
                await dispatch(setToken(data.token));
            } catch(ex){
                console.error(ex);
            } finally{
                await dispatch(setLoading(false));
            }
        }
    }

    public static parseToken(){
        return async (dispatch: any, getState: () => AppState) => {
            try {
                await dispatch(setParseTokenProcessing(true));
                const token = localStorage.getItem('jwt');
                await dispatch(setToken(token));
            } finally{
                await dispatch(setParseTokenProcessing(false));
            }
        }
    }

    private static handleLoginResponse(model: LoginResponseModel, from: Location){
        return async (dispatch: any, getState: () => AppState) => {
            const returnUrl = from ? `${from.pathname}${from.search}`: null;
            await dispatch(setAuthData(model));
            await dispatch(setToken(model.token));

            localStorage.setItem('jwt', model.token);

            window.open(returnUrl ?? playerPaths.home, '_self');
        }
    }
}

export class Selectors {
    public static getAuth(state: AppState) {
        return state.auth;
    }

    public static isChatAdmin(state: AppState, chatId: number) {
        const { adminChatIds } = Selectors.getAuth(state);
        return adminChatIds.includes(chatId);
    }

    public static isGlobalAdmin(state: AppState) {
        const { isGlobalAdmin } = Selectors.getAuth(state);
        return isGlobalAdmin;
    }

    public static getLoggedUserId(state: AppState) {
        const { userId } = Selectors.getAuth(state);
        return userId;
    }
}